import * as React from "react"
import { Link } from "gatsby";
import "./header.css"
import logo from "../../images/logo-bcbsri.svg"

const Header = ({ setMenuOpen }) => {
  return (
    <div class="header">
      <div class="header__constraint">
        <header className="header__content">
          <button className="header__menu-button" onClick={() => setMenuOpen(true)} >
            <div className="hamburger" role="presentation">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <span className="header__menu-button__text">
              Menu
            </span>
          </button>
          <Link to="/">
            <img src={logo} alt="BCBS of Rhode Island Logo" />
          </Link>
        </header>
      </div>
    </div>
  )
}

export default Header
