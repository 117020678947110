import * as React from "react";
import PropTypes from "prop-types";
import "./nested-container.css";

/*
  Usage – can be passed a hex/rgb color value or a variable for background
  Takes a border value of "thick-white", "thin-grey", or defaults to "none"
  Use hasShadow for box shadow, defaults to false
  Use className to add a class.
  Use 'padded' to add 60px padding around the nested container.
  Use 'vertical-padded' to add 56px padding to the top & bottom of the nested container.

  <NestedContainer
    background={"#479130"}
    border={"thick-white"}
    className={"some-class"}
  >
  – OR –
  <NestedContainer
    background={`var(--color-5)`}
    border={"thin-grey"}
    hasShadow
    padded
    vertical-padded
  >
*/

const NestedContainer = ({
  background,
  border,
  hasShadow,
  className,
  padded,
  paddedLast,
  paddedHorizontal,
  children,
  styleName,
}) => (
  <div
    className={`container container--nested border--${border} ${styleName} ${
      hasShadow ? "box-shadow" : ""
    } ${className ? className : ""} ${padded ? "padded" : ""} ${
      paddedLast ? "padded-last" : ""
    }
      `}
    style={{ background: `${background}` }}
  >
    {children}
  </div>
);

NestedContainer.defaultProps = {
  background: ``,
  border: `none`,
  hasShadow: false,
  padded: false,
  paddedLast: false,
};

NestedContainer.propTypes = {
  background: PropTypes.string,
  border: PropTypes.oneOf(["thick-white", "thin-grey", "none"]),
  hasShadow: PropTypes.bool,
  padded: PropTypes.bool,
  paddedLast: PropTypes.bool,
};

export default NestedContainer;
