import * as React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll';
import "./circle-indicator.css"
import iconArrow from '../../images/icons/icon-arrow-circle-down.svg'

/*
  Usage – must include the following params when called.
  - xPosition: Use a string of "left" or "right".
  - yPosition: Use a string of "top" or "bottom".
  - title: Accepts a string to display.
  - button: A boolean true/false value.

  Example:
    <CircleIndicator
      xPosition="right"
      yPosition="top"
      title="top"
      button={true}
    />
*/

const CircleIndicator = (props) => {
    return (
      <div>
        {(() => {
          if (props.button === true) {
            return (
              <button
                className={`circle-indicator circle-indicator--${props.xPosition} circle-indicator--${props.yPosition}`}
                onClick={() => scrollTo('#App')}
              >
                <span>{props.title}</span>
                <img src={iconArrow} alt=""/>
              </button>
            )
          } else {
            return (
              <div className={`circle-indicator circle-indicator--${props.xPosition} circle-indicator--${props.yPosition}`}>
                <span>{props.title}</span>
                <img src={iconArrow} alt=""/>
              </div>
            )
          }
        })()}
      </div>
    )
}

export default CircleIndicator
