import React from "react";
import PropTypes from 'prop-types';

const ChevronIcon = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{ maxWidth: `${size}px` }}>
    <path fill={color} d="M12 8c0 .2-.1.4-.2.5l-4.4 5.2c-.3.3-.8.4-1.1.1-.3-.2-.4-.7-.1-1l4-4.7-4-4.8c-.3-.4-.2-.8.1-1.1.3-.3.8-.2 1.1.1l4.4 5.2c.1.1.2.3.2.5z" />
  </svg>
);

ChevronIcon.defaultProps = {
  color: "#000000",
  size: 16,
};

ChevronIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

export default ChevronIcon;
