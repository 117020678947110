import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CircleIndicator from "../circle-indicator/circle-indicator";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container--large">
        <ul className="footer__social-nav">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="http://twitter.com/BCBSRI"
            >
              <StaticImage
                src="../../images/icons/icon-twitter.svg"
                alt="Twitter"
                height={16}
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.facebook.com/BCBSRI"
            >
              <StaticImage
                src="../../images/icons/icon-facebook.svg"
                alt="Facebook"
                height={16}
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.linkedin.com/company/blue-cross-&-blue-shield-of-rhode-island"
            >
              <StaticImage
                src="../../images/icons/icon-linkedin.svg"
                alt="LinkedIn"
                height={16}
              />
            </a>
          </li>
        </ul>
        <div className="footer__col-wrap">
          <div className="footer__col footer__col--1">
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                className="footer__past-report-link"
                href="https://2020annualreport.bcbsri.com"
              >
                2020 Annual and Community Report
              </a>
            </p>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.bcbsri.com/privacy-policy"
                >
                  Privacy Statement
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.bcbsri.com/how-blue-cross-protecting-your-information"
                >
                  Security &amp; Fraud Protection
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.bcbsri.com/terms-conditions"
                >
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__col footer__col--2">
            <p>
              We comply with applicable Federal civil rights laws and do not
              discriminate on the basis of race, color, national origin, age,
              disability, or sex. We also provide language assistance.{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.bcbsri.com/nondiscrimination-and-language-assistance"
              >
                Read our Nondiscrimination and Language Assistance notice.
              </a>
            </p>
            <p>
              Copyright &copy; 2022 Blue Cross &amp; Blue Shield of Rhode
              Island. All rights reserved. All trademarks unless otherwise noted
              are the property of Blue Cross & Blue Shield of Rhode Island or
              the Blue Cross and Blue Shield Association. Blue Cross & Blue
              Shield of Rhode Island is an independent licensee of the Blue
              Cross and Blue Shield Association.
            </p>
          </div>
        </div>
        <CircleIndicator
          xPosition="right"
          yPosition="top"
          title="top"
          button={true}
        />
      </div>
    </footer>
  );
};

export default Footer;
