import * as React from "react";
import PropTypes from "prop-types";
import "./full-width-container.css";

/*
  Usage – can be passed a hex/rgb color value or a variable for background
  Can be passed a dark prop if the backgound is dark, which will set the color
  of the container to white.
  
  <FullWidthContainer background={"#479130"}>
  – OR –
  <FullWidthContainer 
    background={`var(--color-5)`}
    dark
  >

*/

const FullWidthContainer = ({
  background,
  dark,
  single,
  singleHero,
  children,
  styleName,
}) => (
  <div
    className={`container container--full ${styleName} ${
      single ? "container--full-single" : ""
    } ${singleHero ? "container--full-single-hero" : ""}`}
    style={{
      background: `${background}`,
      color: `${dark ? "#fff" : "inherit"}`,
    }}
  >
    {children}
  </div>
);

FullWidthContainer.defaultProps = {
  styleName: ``,
  background: ``,
  dark: false,
  single: false,
  singleHero: false,
};

FullWidthContainer.propTypes = {
  background: PropTypes.string,
  dark: PropTypes.bool,
  single: PropTypes.bool,
  singleHero: PropTypes.bool,
};

export default FullWidthContainer;
