import React from "react";
import PropTypes from "prop-types";
import "./row.css";
/*
  Usage – can be used for any number of equally sized columns. 2 or 3 columns
  work well with this component. Each direct child will be equally sized.
  Can be passed a divider prop to create a vertical separator and some padding 
  between children.
  In most cases, Row components will be placed inside a NestedContainer 
  component.

  <RowEqual divider>
    <div><h1>here's some content</h1></div>
    <div><h1>here's some content</h1></div>
  </RowEqual>
  
*/

const RowEqual = ({ divider, doubleGap, children, styleName }) => (
  <div
    className={`flex-row flex-row--equal ${
      divider ? "divider" : ""
    } ${styleName} ${doubleGap ? "gap--double" : "gap--standard"}`}
  >
    {children}
  </div>
);

RowEqual.defaultProps = {
  divider: false,
};

RowEqual.propTypes = {
  divider: PropTypes.bool,
};

export default RowEqual;
